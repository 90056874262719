import "core-js/modules/es.array.push.js";
import { createElementVNode as _createElementVNode, isRef as _isRef, toDisplayString as _toDisplayString, unref as _unref, normalizeClass as _normalizeClass, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createVNode as _createVNode } from "vue";
import _imports_0 from '@/assets/index/logo_w.png';
import _imports_1 from '@/assets/index/lange.png';
import _imports_2 from '@/assets/index/coinBg.png';
const _hoisted_1 = {
  class: "index"
};
const _hoisted_2 = {
  class: "headers acea-row row-between-wrapper"
};
const _hoisted_3 = {
  class: "rit"
};
const _hoisted_4 = {
  class: "maxWidth"
};
const _hoisted_5 = {
  class: "top1"
};
const _hoisted_6 = {
  class: "texts"
};
const _hoisted_7 = {
  class: "infos"
};
const _hoisted_8 = {
  class: "infos"
};
const _hoisted_9 = {
  class: "lists max-846"
};
const _hoisted_10 = {
  class: "list"
};
const _hoisted_11 = {
  class: "title"
};
const _hoisted_12 = {
  class: "list"
};
const _hoisted_13 = {
  class: "title"
};
const _hoisted_14 = {
  class: "list"
};
const _hoisted_15 = {
  class: "title"
};
const _hoisted_16 = {
  class: "list"
};
const _hoisted_17 = {
  class: "title"
};
const _hoisted_18 = {
  class: "All_lange_show_dia"
};
const _hoisted_19 = {
  class: "lists"
};
const _hoisted_20 = ["onClick"];
const _hoisted_21 = {
  class: "let"
};
import { router } from "@/router";
import { computed, ref } from "vue";
import { useUserStore } from "@/stores/user";
import enUS from "vant/es/locale/lang/en-US";
import frFR from "vant/es/locale/lang/fr-FR";
import enES from "vant/es/locale/lang/es-ES";
import itIT from "vant/es/locale/lang/it-IT";
import { Locale } from "vant";
import { useI18n } from "vue-i18n";
import enTH from 'vant/es/locale/lang/th-TH';
export default {
  __name: 'index',
  setup(__props) {
    const userStore = useUserStore();
    let show = ref(false);
    let bgShow = ref(false);
    const openUrl = url => {
      router.push(url);
    };
    const mousedown = () => {
      bgShow.value = true;
    };
    const mouseup = () => {
      setTimeout(() => {
        bgShow.value = false;
        openUrl('/login');
      }, 200);
    };
    const localeActions = computed(() => userStore.localeActions);
    const {
      t,
      locale
    } = useI18n();
    const onSelect = item => {
      changeLang(valueLang.value);
      show.value = false;
    };
    const changeLang = lang => {
      locale.value = lang;
      const langMap = {
        'en_US': {
          value: enUS,
          key: 'en-US'
        },
        'fr_FR': {
          value: frFR,
          key: 'fr-FR'
        },
        'es_ES': {
          value: enES,
          key: 'es-ES'
        },
        'it_IT': {
          value: itIT,
          key: 'it-IT'
        },
        'en_TH': {
          value: enTH,
          key: 'en_TH'
        }
      };
      Locale.use(langMap[lang]['key'], langMap[lang]['value']);
      userStore.setLang(lang);
      window.localStorage.setItem('lang', lang);
    };
    const lang = computed(() => userStore.lang);
    console.log(lang);
    const valueLang = ref();
    valueLang.value = userStore.lang;
    const setIndex = item => {
      valueLang.value = item.value;
    };
    return (_ctx, _cache) => {
      const _component_van_icon = _resolveComponent("van-icon");
      const _component_el_dialog = _resolveComponent("el-dialog");
      return _openBlock(), _createElementBlock(_Fragment, null, [_createElementVNode("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_cache[2] || (_cache[2] = _createElementVNode("div", {
        class: "logos"
      }, [_createElementVNode("img", {
        src: _imports_0,
        alt: ""
      })], -1)), _createElementVNode("div", _hoisted_3, [_createElementVNode("img", {
        onClick: _cache[0] || (_cache[0] = $event => _isRef(show) ? show.value = true : show = true),
        src: _imports_1,
        alt: ""
      })])]), _createElementVNode("div", _hoisted_4, [_createElementVNode("div", _hoisted_5, [_cache[4] || (_cache[4] = _createElementVNode("img", {
        src: _imports_2,
        alt: ""
      }, null, -1)), _createElementVNode("div", _hoisted_6, [_cache[3] || (_cache[3] = _createElementVNode("div", {
        class: "name"
      }, "Uniswap ", -1)), _createElementVNode("div", _hoisted_7, _toDisplayString(_ctx.$t('index.banTip')), 1), _createElementVNode("div", _hoisted_8, _toDisplayString(_ctx.$t('banTip2')), 1), _createElementVNode("div", {
        class: _normalizeClass(["buttons", {
          'clickBt': _unref(bgShow)
        }]),
        onMousedown: mousedown,
        onMouseup: mouseup
      }, _toDisplayString(_ctx.$t('index.button')), 35)])]), _createElementVNode("div", _hoisted_9, [_createElementVNode("div", _hoisted_10, [_cache[5] || (_cache[5] = _createElementVNode("span", null, "History", -1)), _createElementVNode("div", _hoisted_11, _toDisplayString(_ctx.$t('index.tip1')), 1)]), _createElementVNode("div", _hoisted_12, [_cache[6] || (_cache[6] = _createElementVNode("span", null, "Overview", -1)), _createElementVNode("div", _hoisted_13, _toDisplayString(_ctx.$t('index.tip2')), 1)]), _createElementVNode("div", _hoisted_14, [_cache[7] || (_cache[7] = _createElementVNode("span", null, "Protocol", -1)), _createElementVNode("div", _hoisted_15, _toDisplayString(_ctx.$t('index.tip3')), 1)]), _createElementVNode("div", _hoisted_16, [_cache[8] || (_cache[8] = _createElementVNode("span", null, "Rise", -1)), _createElementVNode("div", _hoisted_17, _toDisplayString(_ctx.$t('index.tip4')), 1)])])])]), _createVNode(_component_el_dialog, {
        modelValue: _unref(show),
        "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => _isRef(show) ? show.value = $event : show = $event),
        title: _ctx.$t('langeSet'),
        width: "448",
        "align-center": "",
        center: "",
        style: {
          "border-radius": "16px"
        }
      }, {
        default: _withCtx(() => [_createElementVNode("div", _hoisted_18, [_createElementVNode("div", _hoisted_19, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(localeActions.value, (item, index) => {
          return _openBlock(), _createElementBlock("div", {
            class: "acea-row row-between-wrapper",
            onClick: $event => setIndex(item),
            key: index
          }, [_createElementVNode("div", _hoisted_21, _toDisplayString(item.name), 1), _createElementVNode("div", {
            class: _normalizeClass(["rit acea-row row-center-wrapper", {
              'colors': valueLang.value === item.value
            }])
          }, [valueLang.value === item.value ? (_openBlock(), _createBlock(_component_van_icon, {
            key: 0,
            name: "success",
            size: "20px",
            color: "#fff"
          })) : _createCommentVNode("", true)], 2)], 8, _hoisted_20);
        }), 128))]), _createElementVNode("div", {
          class: "isButtons",
          onClick: onSelect
        }, _toDisplayString(_ctx.$t('Sure')), 1)])]),
        _: 1
      }, 8, ["modelValue", "title"])], 64);
    };
  }
};